import {
    StyleSheet,
    Text,
    // TouchableOpacity,
    View,
    Image,
} from 'react-native'
import React, {
    useEffect,
    useState,
} from 'react'
import Log from '@utils/log'
import Feather from '@components/icons/feather'
import Styles, {
    CommonStyle as cs,
    scaleSize,
} from '@styles'
import PropTypes from 'prop-types'
import Toast from '@components/toast'
import ChipCalculate from '@components/chips/calculate'
import ProgressList from '../progresslist'

import axios from 'axios'
import ApiFillCredit from '@apis/fillcredit'
import ApiChip from '@apis/chip'
import { useSelector } from 'react-redux'
import useLoading from '@hooks/useLoading'

const { SC, Colors } = Styles


const Progress = (props) => {

    const table = useSelector(state => state.table)
    const [loading, setLoading] = useLoading()
    const tableUuid = table && table.uuid
    const source = axios.CancelToken.source()

    const {
        myProps,
    } = props

    useEffect(() => {
        getAllFill()
    }, [])

    const [fills, setFills] = useState([])
    const [credits, setCredits] = useState([])
    const [allChips, setAllChips] = useState([])

    const getAllFill = async () => {
        setLoading(true)
        try {
            const respFill = await ApiFillCredit.fills(source.token, tableUuid)
            setFills(respFill.data)
            const respCredit = await ApiFillCredit.credits(source.token, tableUuid)
            setCredits(respCredit.data)
            await getAllChips()
        } catch (e) {
            Log.log('error', e)
            Toast.exception(e, 'Fail to get status')
        }
        setLoading(false)
    }

    const getAllChips = async () => {
        let allChips = new Map()
        const respChisets = await ApiChip.getChipsets(source.token, tableUuid)
        for (const chipset of respChisets.data) {
            const respChips = await ApiChip.getChips(source.token, chipset.uuid)
            const chips = respChips.data
            for (const chip of chips) {
                allChips.set(chip.uuid, {
                    ...chip,
                    count: 0,
                    value: +chip.value,
                    colorText: 'black',
                    colorBg: '',
                    title: (+chip.value).toString(),
                })
            }
        }
        // await Promise.all(respChisets.data.map(async chipset => {
        //     const respChips = await ApiChip.getChips(source.token, chipset.uuid)
        //     allChips = allChips.concat(respChips.data)
        // }))
        setAllChips(allChips)
    }

    // const [chips, setChips] = useState([{
    //     uuid: '123',
    //     colour: 'Blue',
    //     value: 10,
    //     count: 0,
    // }, {
    //     uuid: '124',
    //     colour: 'Black',
    //     value: 20,
    //     count: 0,
    // }])

    // useEffect(() => {
    //     setChips(ChipCalculate.mapChips(chips, [], 1))
    // }, [])

    // const onEnter = () => {
    //     Toast.show(`Successful`, {
    //         type: 'success',
    //     })
    // }

    return (
        <View style={[styles.container, cs.pa, cs.roundedEnd]}>
            <ProgressList style={styles.section} title='Fill' listItems={fills} allChips={allChips} />
            <ProgressList style={styles.section} title='Credit' listItems={credits} allChips={allChips} />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        flexGrow: 2,
        backgroundColor: Colors.DASH_MAIN,
        borderBottomStartRadius: SC.BORDER_RADIUS,
    },
    section: {
        flex: 1,
        alignItems: 'center',
    },
})

Progress.propTypes = {
    myProps: PropTypes.any,
}

export default Progress