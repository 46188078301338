import React, { useEffect, useCallback, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import axios from 'axios'
import {
  // Platform,
  // SafeAreaView,
  // StatusBar,
  StyleSheet,
  // Text,
  // TextInput,
  // TouchableOpacity,
  View,
} from 'react-native'
import { useIsFocused } from '@react-navigation/native'

import Constants from '@constants'
import Log from '@utils/log'
import ChipCalculate from '@components/chips/calculate'

import Toast from '@components/toast'
// import Tabs from '@components/tabs'
// import Button from '@components/button'
// import Input from '@components/input'
// import Chip from '@components/chip'
// import Chips from '@components/chips'
// import Slider from '@components/slider'
// import ChipInputSlider from '@screens/base/chipinputslider'
import { TabHeader } from '@components/tabs'
import ApiChip from '@apis/chip'

import Styles, { CommonStyle as cs } from '@styles'
const { SC, Colors } = Styles

// // react-redux
// import { useSelector, useDispatch } from 'react-redux'
// import { updateUsername } from '@actions/users'

import ApiPit from '@apis/pit'
import ApiTable from '@apis/table'
import Fill from './layout/fill'
import Credit from './layout/credit'
import Progress from './layout/progress'
import { useSelector } from 'react-redux'

const FillCredit = (props) => {
  const {
    // navigation,
    setLoading,
  } = props
  const isFocused = useIsFocused()
  const source = axios.CancelToken.source()
  const table = useSelector(state => state.table)
  const tableUuid = table && table.uuid

  // init
  const TAB_FILL = 'fill'
  const TAB_CREDIT = 'credit'
  const TAB_PROGRESS = 'progress'

  const [tabs, setTabs] = useState([{
    id: TAB_FILL,
    name: 'Fill',
  }, {
    id: TAB_CREDIT,
    name: 'Credit',
  }, {
    id: TAB_PROGRESS,
    name: 'Progress',
  }])

  const [tabIndex, setTabIndex] = useState(0)
  const [chipIndex, setChipIndex] = useState(0)

  const [fills, setFills] = useState([{
    uuid: '123',
    colour: 'Blue',
    value: 10,
    count: 0,
  }, {
    uuid: '124',
    colour: 'Black',
    value: 20,
    count: 0,
  }])

  const [credits, setCredits] = useState([{
    uuid: '123',
    colour: 'Blue',
    value: 10,
    count: 0,
  }, {
    uuid: '124',
    colour: 'Red',
    value: 50,
    count: 0,
  }])

  const [selectedTab, setSelectedTab] = useState(tabs[0])

  const chipsets = [{
    id: TAB_FILL,
    chips: fills,
    setChips: setFills,
  }, {
    id: TAB_CREDIT,
    chips: credits,
    setChips: setCredits,
  }]

  const [chips, setChips] = useState([])
  const [fillChips, setFillChips] = useState([])

  const chipset = useMemo(() => {
    let tabId = (tabIndex < tabs.length) && tabs[tabIndex] && tabs[tabIndex].id
    switch (tabId) {
      case TAB_FILL:
        return fills
      case TAB_CREDIT:
        return credits
      default:
        Log.error(`Invalid tabIndex: ${tabIndex}'s tabId: ${tabId} for chipset`)
        return []
    }
  }, [fills, credits, tabIndex])

  useEffect(() => {
    Log.log('fillcredit: init', { isFocused })
    if (isFocused) {
      init()
      getChips()
    }
  }, [isFocused])


  const getChips = async () => {
    try {
      const resp = await ApiChip.getChipsets(source.token, tableUuid)
      const chipsets = resp.data[0]
      const respChips = await ApiChip.getChips(source.token, chipsets.uuid)
      const chips = []
      const fillChips = []
      respChips.data.forEach(item => {
        chips.push({
          ...item,
          count: 0,
          value: +item.value,
          colorText: 'black',
          colorBg: '',
          title: (+item.value).toString(),
        })

        fillChips.push({
          ...item,
          count: 0,
          value: +item.value,
          colorText: 'black',
          colorBg: '',
          title: (+item.value).toString(),
        })
      })
      setChips(chips)
      setFillChips(fillChips)

    } catch (e) {
      Toast.exception(e, 'Fail to get chips')
    }
  }

  const init = useCallback(async () => { // todo: implement
    Log.log('fillcredit: init')
    try {
      setLoading(true)
      // API calls here
      await getTables()
      await getChipsets()
      setLoading(false)
    } catch (error) {
      setLoading(false)
      Toast.show(`Init error: ${error}`)
    }
  }, [getTables, tabs, setTabs])

  const getTables = useCallback(async () => {
    let response = await ApiPit.getPitUuid(source.token, Constants.SERIAL_CODE)
    if (!response || !response.data || !response.data.uuid) {
      throw new Error('invalid response or data or uuid')
    }

    let pitUuid = response.data.uuid
    response = await ApiTable.getAll(source.token, pitUuid)
    Log.log('fillcredit: getTables', response)
  }, [])

  const getChipsets = useCallback(async () => {
    Log.log('fillcredit: getChipsets', { fills, credits, ChipCalculate })
    for (let t in chipsets) {
      let cs = chipsets[t]
      let tabId = cs && cs.id
      let chips = cs && cs.chips
      let setChips = cs && cs.setChips
      let formatted = ChipCalculate.mapChips(chips, [], tabId)
      await setChips([].concat(formatted))
    }
  }, [fills, credits])

  useEffect(() => {
    let all = _.map(fills, (fill) => {
      return fill.count
    })
    Log.log('fillcredit: chipset/tabIndex changed', { tabIndex, fills, fillsAll: all.join(',') })
  }, [fills, credits, tabIndex])

  const onChipChangeValue = useCallback((chip, count) => {
    // console.log('chips set', { chip, count })
    if (tabIndex < 0 || tabIndex >= tabs.length) {
      Log.error(`invalid tabIndex: ${tabIndex}`)
      return
    }
    let tabInfo = tabs[tabIndex]
    Log.debug('tabInfo', tabInfo)
    let tabId = tabInfo && tabInfo.id
    let found = _.find(chipsets, (cs) => cs.id === tabId)
    if (!found || !found.chips || !found.setChips) {
      Log.error(`invalid tabIndex: ${tabIndex}'s tabId: ${tabId}`, chipsets)
      return
    }
    let chips = found.chips
    let setChips = found.setChips

    // validate
    if (count == null || isNaN(parseInt(count, 10))) {
      count = 0
    } else {
      count = parseInt(count, 10)
    }

    let val = chip.value
    let cid = chip.cid
    found = _.find(chips, (c) => c.value === val && c.cid === cid) // make sure you set chip correctly
    if (!found) {
      Log.error(`invalid chip: ${val}, cid: ${cid}`, chips)
    }
    if (found) {
      found.count = count
    }

    // debug
    // let chipsConcat = _.map(chips, (chip) => {
    //   return chip.count
    // })
    // console.log('chips set', { chips, concat: chipsConcat.join(',') })
    setChips([].concat(chips))
  }, [fills, credits, tabIndex])

  const onReset = () => {
    //
  }

  const onSubmit = () => {
    //
  }

  return (
    <View style={[styles.container, cs.pa]}>
      <TabHeader
        tabs={tabs}
        selectedItem={selectedTab}
        onChangeTab={setSelectedTab}
      />
      <View style={styles.container}>
        {selectedTab.id === TAB_FILL && <Fill chips={fillChips} />}
        {selectedTab.id === TAB_CREDIT && <Credit chips={chips} />}
        {selectedTab.id === TAB_PROGRESS && <Progress chips={chips} />}
      </View>
      {/* <View style={styles.wrapper}>

        <View style={styles.left}>
          <View style={styles.leftTop}>
            <Tabs
              tabs={tabs}
              index={tabIndex}
              onChangeTab={setTabIndex}
            />
          </View>
          <View style={styles.leftMain}>
            {
              chipset ?
                <Chips
                  chips={chipset}
                  onPress={(chip, index) => setChipIndex(index)}
                  onChangeValue={(chip, value) => onChipChangeValue(chip, value)}
                /> : null
            }
          </View>
        </View>
        <View style={styles.right}>
          <View style={styles.rightStub}></View>
          <View style={styles.rightTop}>
            <View style={styles.panel}>
              {
                chipset && chipIndex < chipset.length && chipset[chipIndex] ?
                  <ChipInputSlider
                    chip={chipset[chipIndex]}
                    min={0}
                    max={100}
                    onChangeValue={onChipChangeValue}
                  /> : null
              }
            </View>
          </View>
          <View style={styles.rightCenter}>
            <View style={styles.infoWrapper}>
              <View style={styles.infoLine}>
                <Text style={styles.infoLabel}>Credit Amount:</Text>
              </View>
              <View style={styles.infoLine}>
                <Text style={styles.infoCurrency}>RM</Text>
                <Text style={styles.infoValue}>2600</Text>
              </View>
            </View>
          </View>
          <View style={[styles.rightBottom]}>
            <Button
              title="Reset"
              disabled={false}
              onPress={onReset}
              style={[styles.button, styles.buttonReset]} />
            <Button
              title="Submit"
              disabled={false}
              onPress={onSubmit}
              style={styles.button} />
          </View>
        </View>
      </View> */}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    // borderRadius: SC.BORDER_RADIUS,
    // backgroundColor:'red',
    // flex: 1,
    flexGrow: 1,
    // flexDirection: 'column',
    // justifyContent: 'center',
    // alignItems: 'center',
  },
  wrapper: {
    ...StyleSheet.absoluteFill,
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
  },
  // left
  left: {
    width: '65%',
    height: '100%',
    // backgroundColor: 'green',
    position: 'relative',
    padding: SC.GAP_S * SC.SCALE,
    flexDirection: 'column',
  },
  leftTop: {
    // width: '100%',
    // flexWrap: 'wrap',
    flexDirection: 'row',
  },
  leftMain: {
    width: '100%',
    // height: '100%',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderBottomLeftRadius: 9,
    backgroundColor: Colors.DASH_MAIN,
  },
  // right
  right: {
    width: '35%',
    height: '100%',
    position: 'relative',
    flexDirection: 'column',
  },
  rightStub: {
    height: 22 * SC.SCALE,
  },
  rightTop: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.DASH_MAIN,
    borderTopRightRadius: 9,
  },
  rightCenter: {
    flexWrap: 'wrap',
    padding: SC.GAP * SC.SCALE,
    paddingBottom: 0,
    backgroundColor: Colors.DASH_MAIN,
  },
  rightBottom: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: SC.GAP * SC.SCALE,
    backgroundColor: Colors.DASH_MAIN,
    marginBottom: SC.GAP_S * SC.SCALE,
    borderBottomRightRadius: 9,
  },
  panel: {
    width: '80%',
    // backgroundColor: 'white',
    borderRadius: 9,
    padding: SC.GAP_S * SC.SCALE,
    justifyContent: 'center',
    alignItems: 'center',
  },
  infoWrapper: {
    padding: SC.GAP * SC.SCALE,
    marginBottom: SC.GAP * SC.SCALE,
    backgroundColor: Colors.BUTTON_BG,
    borderRadius: 9,
  },
  infoLine: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  infoLabel: {
    color: 'white',
    fontSize: 8 * SC.SCALE,
    flexWrap: 'wrap',
    flex: 1,
    flexBasis: 0,
  },
  infoCurrency: {
    color: 'white',
    fontSize: 12 * SC.SCALE,
    flexWrap: 'wrap',
    // marginLeft: 4 * SC.SCALE,
  },
  infoValue: {
    color: 'white',
    fontSize: 8 * SC.SCALE,
    flex: 1,
    textAlign: 'right',
  },
  button: {
    // width: '100%',
    flex: 1,
    backgroundColor: Colors.BUTTON_WIZ,
  },
  buttonReset: {
    backgroundColor: Colors.BUTTON_RESET,
    marginRight: SC.GAP_S * SC.SCALE,
  },
})

FillCredit.propTypes = {
  navigation: PropTypes.any,
  setLoading: PropTypes.func,
}

export default FillCredit
