import _ from 'lodash'
import { CLEAR_AUTH, UPDATE_TOKEN, UPDATE_NAME } from '@actions/auth'

const authDefault = _.assign({}, {
  token: undefined,
  name: undefined,
})

const authReducer  = (auth = authDefault, action) => {
  switch (action.type) {
    case CLEAR_AUTH:
      return _.assign({}, authDefault)
    case UPDATE_TOKEN:
      return {
        ...auth,
        token: action.token,
      }
    case UPDATE_NAME:
      return {
        ...auth,
        name: action.name,
      }
    default:
      return auth
  }
}

export default authReducer
