import React, {
  forwardRef,
  useImperativeHandle,
  useEffect,
  useState,
  // useMemo,
} from 'react'

import {
  StyleSheet,
  // Text,
  // TouchableOpacity,
  View,
  // Image,
  // TouchableOpacity,
} from 'react-native'
import PropTypes from 'prop-types'
import Summary from '@components/chipscontrol/summary'
import BtnToggleCash from '@components/chipscontrol/BtnToggleCash'
import CashInputSlider from '@screens/base/cashinputslider'
import ChipInputSlider from '@screens/base/chipinputslider'
import Constants from '@constants'
import Button from '@components/button'

import Styles, { CommonStyle as cs, scaleSize } from '@styles'
const { SC, Colors } = Styles

const SliderPanel = forwardRef((props, ref) => {
  const {
    chip,
    onChipChangeValue,
    onEnter,
    onEnterDisabled,
    onReset,
    isCashMode,
    noCashToggle,
    noButtons,
    totalChipAmount,
    min = 0,
    max = 100,
    prependButton,
    appendButton,
    summary,
    stylePanel,
  } = props

  const [showCashSlider, setShowCashSlider] = useState(false)
  // const [totalChipAmount, setTotalChipAmount] = useState(0)
  const [totalCashAmount, setTotalCashAmount] = useState(0)
  const [cash, setCash] = useState({
    value: Constants.CASH_CONST,
    min: Constants.CASH_MIN,
    max: Constants.CASH_MAX,
    count: 0,
  })

  useImperativeHandle(ref, () => ({
    toggleShowCash: (showCash) => { setShowCashSlider(showCash) },
  }))

  useEffect(() => {
    if (isCashMode) {
      setShowCashSlider(false)
    }
  }, [isCashMode])

  const onCashChange = (cash, count) => {
    cash.count = count
    setCash({ ...cash })
    setTotalCashAmount(cash.count)
  }

  const onLocalReset = () => {
    cash.count = 0
    setCash({ ...cash })
    setTotalCashAmount(cash.count)
    onReset?.()
  }

  return (<View style={[styles.container, stylePanel, cs.roundedEnd, cs.pa]}>
    <View>
      {
        !isCashMode && !noCashToggle ?
        <BtnToggleCash
          isToggle={showCashSlider}
          onPress={() => setShowCashSlider(!showCashSlider)}
         /> : null
      }
      {
        (!showCashSlider || isCashMode) && chip &&
        <ChipInputSlider
          chip={chip}
          min={0}
          max={100}
          onChangeValue={onChipChangeValue}
          isCashMode={isCashMode}
        />
      }
      {
        showCashSlider &&
        <CashInputSlider
          chip={cash}
          min={min}
          max={max}
          onChangeValue={onCashChange}
        />
      }
    </View>
    <View>
      {summary?.({
        total_cash_amount: totalCashAmount,
        total_chip_amount: totalChipAmount,
      })}
      {!summary && <Summary
        totalCashAmount={totalCashAmount}
        totalChipAmount={totalChipAmount}
      />}
      {prependButton}
      {
        !noButtons ?
        <View style={[{ flexDirection: 'row', paddingTop: scaleSize(5) }]}>
          <Button
            title="Reset"
            disabled={false}
            onPress={onLocalReset}
            style={[styles.button, styles.buttonReset]} />
          <Button
            title="Enter"
            disabled={onEnterDisabled ? onEnterDisabled() : false}
            onPress={onEnter}
            style={styles.button} />
        </View> : null
      }
      {appendButton}
    </View>
  </View>)
})

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'space-between',
    backgroundColor: Colors.DASH_MAIN,
    marginStart: 10,
    paddingVertical: scaleSize(10),
    paddingHorizontal: scaleSize(10),
  },
  buttonReset: {
    backgroundColor: Colors.BUTTON_RESET,
    marginRight: SC.GAP_S * SC.SCALE,
  },
  button: {
    flex: 1,
    backgroundColor: Colors.BUTTON_WIZ,
  },
})

SliderPanel.displayName = 'SliderPanel'

SliderPanel.propTypes = {
  chip: PropTypes.any.isRequired,
  onChipChangeValue: PropTypes.func.isRequired,
  onEnter: PropTypes.func.isRequired,
  onEnterDisabled: PropTypes.func,
  onReset: PropTypes.func.isRequired,
  isCashMode: PropTypes.bool,
  noCashToggle: PropTypes.bool,
  noButtons: PropTypes.bool,
  totalChipAmount: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  prependButton: PropTypes.element,
  appendButton: PropTypes.element,
  summary: PropTypes.func,
  stylePanel: PropTypes.any,
}

export default SliderPanel