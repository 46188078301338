import React, {
  // useEffect,
  useMemo,
  useState,
  useRef,
} from 'react'
import {
  StyleSheet,
  // Text,
  // TouchableOpacity,
  View,
  // Image,
  // TouchableOpacity,
} from 'react-native'

// import Log from '@utils/log'
// import Feather from '@components/icons/feather'
import PropTypes from 'prop-types'
import Chips from '@components/chips'
import SliderPanel from './sliderpanel'
// import LoadingView from '../loadingview'

import Styles, { CommonStyle as cs, scaleSize } from '@styles'
const {
  SC,
  Colors,
} = Styles


const ChipsControl = (props) => {
  const {
    chips,
    onChipsChange,
    onEnter,
    onEnterDisabled,
    isCashMode,
    noCashToggle,
    noButtons,
    prependLeftPanel,
    appendLeftPanel,
    prependButton,
    appendButton,
    leftPanelJustifyContent = 'space-between',
    styleLeftPanel,
    styleSliderPanel,
    /*
    summary function with object params
    {
    total_cash_amount: totalCashAmount,
    total_chip_amount: totalChipAmount,
    }
    refer fill.js for usage
    */
    summary,
  } = props
  const refSliderPanel = useRef()

  const [chipIndex, setChipIndex] = useState(0)
  const [totalChipAmount, setTotalChipAmount] = useState(0)
  const onChipsSelect = (chip, index) => {
    setChipIndex(index)
  }

  useMemo(() => {
    let total = 0
    chips.forEach(item => {
      total += item.count * item.value
    })
    setTotalChipAmount(total)
    return total
  }, [chips])

  const onShowCashToggle = (showCash) => {
    if (refSliderPanel.current && refSliderPanel.current.toggleShowCash) {
      let ref = refSliderPanel.current
      ref.toggleShowCash(showCash)
    }
  }

  const onChipChangeValue = (chip, count) => {
    const found = chips.find(item => item.uuid === chip.uuid)
    if (found) {
      found.count = +count
      onChipsChange([...chips])
    }
  }

  const onReset = () => {
    chips.forEach(item => item.count = 0)
    onChipsChange([...chips])
  }

  return (
    <View style={[styles.container, cs.roundedEnd]}>
      <View style={[styles.leftPanel, styleLeftPanel, { justifyContent: leftPanelJustifyContent }]}>
        {prependLeftPanel}
        <View style={[{ paddingVertical: scaleSize(10), height: '100%' }]}>
          <Chips
            chips={chips}
            onPress={(chip, index) => {
              onShowCashToggle(false)
              onChipsSelect(chip, index)
            }}
            onChangeValue={(chip, value) => onChipChangeValue(chip, value)}
            isCashMode={isCashMode}
          />
        </View>
        {appendLeftPanel}
      </View>
      <View style={{ flex: 2 }}>
        <SliderPanel
          ref={refSliderPanel}
          chip={chips[chipIndex]}
          onChipChangeValue={onChipChangeValue}
          onEnter={onEnter}
          onEnterDisabled={onEnterDisabled}
          onReset={onReset}
          isCashMode={isCashMode}
          noCashToggle={noCashToggle}
          noButtons={noButtons}
          totalChipAmount={totalChipAmount}
          prependButton={prependButton}
          appendButton={appendButton}
          summary={summary}
          stylePanel={styleSliderPanel}
        />
      </View>
    </View >
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexGrow: 2,
  },
  leftPanel: {
    flex: 2,
    backgroundColor: Colors.DASH_MAIN,
    flexGrow: 3,
    paddingHorizontal: scaleSize(15),
    paddingVertical: scaleSize(5),
    borderBottomLeftRadius: SC.BORDER_RADIUS,
  },
})

ChipsControl.propTypes = {
  chips: PropTypes.array.isRequired,
  onChipsChange: PropTypes.func.isRequired,
  onEnter: PropTypes.func.isRequired,
  onEnterDisabled: PropTypes.func,
  isCashMode: PropTypes.bool,
  noCashToggle: PropTypes.bool,
  noButtons: PropTypes.bool,
  prependLeftPanel: PropTypes.element,
  appendLeftPanel: PropTypes.element,
  prependButton: PropTypes.element,
  appendButton: PropTypes.element,
  leftPanelJustifyContent: PropTypes.string,
  styleLeftPanel: PropTypes.any,
  styleSliderPanel: PropTypes.any,
  summary: PropTypes.func,
  laoding: PropTypes.bool,
}

export default ChipsControl