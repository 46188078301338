export const CLEAR_AUTH = 'CLEAR_AUTH'
export const UPDATE_TOKEN = 'UPDATE_TOKEN'
export const UPDATE_NAME = 'UPDATE_NAME'

export const clearAuth = () => ({
  type: CLEAR_AUTH,
})

export const updateToken = (token) => ({
  type: UPDATE_TOKEN,
  token,
})

export const updateName = (name) => ({
  type: UPDATE_NAME,
  name,
})
