import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
// import _ from 'lodash'
import axios from 'axios'
import {
  ActivityIndicator,
  Pressable,
  StyleSheet,
  Text,
  // TouchableOpacity,
  View,
} from 'react-native'

// import Constants from '@constants'
import Log from '@utils/log'
import ChipCalculate from '@components/chips/calculate'
import { useIsFocused } from '@react-navigation/native'
import useLoading from '@hooks/useLoading'

import Dialog from '@components/dialog'
// import Dialog from '@components/dialog'
import Feather from '@components/icons/feather'
import Toast from '@components/toast'
// import Tabs from '@components/tabs'
// import Input from '@components/input'
import ChipsControl from '@components/chipscontrol'
import DetailView from '@components/detailview'

import Styles from '@styles'
const { SC, Colors } = Styles
import {
  CommonStyle as cs,
  scaleSize,
} from '@styles'

// react-redux
import { useSelector /* , useDispatch */ } from 'react-redux'
// import { PARAM_TABLE_CHIPS, updateTable } from '@actions/table'

import ApiDrop from '@apis/drop'

import useSubmit from '../hooks/useSubmit'

const DialogDrop = (props) => {
  const {
    visible,
    setVisible,
    refreshCode,
    onMessage,
  } = props
  const isFocused = useIsFocused()
  const source = axios.CancelToken.source()
  const {onUpdateFastDrop} = useSubmit(source.token)

  const [loading, setLoading] = useLoading()
  const [chips, setChips] = useState([{
    uuid: '123',
    colour: 'Blue',
    value: 10,
    count: 0,
  }, {
    uuid: '124',
    colour: 'Black',
    value: 20,
    count: 0,
  }])
  // const [tabIndex, setTabIndex] = useState(0)
  const [lastAmount, setLastAmount] = useState('')
  const [lastUuid, setLastUuid] = useState()

  const table = useSelector(state => state.table)
  const tableUuid = table && table.uuid

  useEffect(() => {
    if (isFocused) {
      init()
    }
    // setChips(ChipCalculate.mapChips(chips, [], 1))
    return deinit
  }, [refreshCode])

  const init = async () => {
    try {
      setLoading(true)
      // API calls here
      let promises = []
      let promise
      promise = getCashList(tableUuid)
      promises.push(promise)
      promise = getLastDrop(tableUuid)
      promises.push(promise)
      let responses = await Promise.all(promises)
      if (responses && responses.length === 2) {
        let cashlist = responses[0]
        let lastdrop = responses[1]
        tabulateLastDrop(cashlist, lastdrop)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      Toast.show(`Init error: ${error}`)
    }
  }

  const getCashList = async (tableUuid) => {
    if (!tableUuid || tableUuid.length <= 0) {
      throw new Error('invalid table UUID')
    }
    let response

    response = await ApiDrop.getCashList(source.token)
    Log.log(`wizard-opening: all cash: ${tableUuid}`, response)
    if (!response || !response.data) throw new Error('invalid cash list data')

    let cashlist = response.data
    // cashlist = _.map(cashlist, (cl, index) => {
    //   // console.log('-- cl', cl)
    //   // let value = parseInt(cl, 10)
    //   return {
    //     uuid: index,
    //     title: `${parseInt(cl.value, 10)}`,
    //     value: parseInt(cl.value, 10),
    //     count: 0,
    //   }
    // })
    // setChips(cashlist)
    return cashlist
  }

  const getLastDrop = async (tableUuid) => {
    if (!tableUuid || tableUuid.length <= 0) {
      throw new Error('invalid table UUID')
    }
    let response

    response = await ApiDrop.getLast(source.token, tableUuid)
    Log.log(`wizard-opening: last drop: ${tableUuid}`, response)
    if (!response || !response.data) throw new Error('invalid last drop data')
    let lastdrop = response.data
    let lastamount = parseInt(lastdrop && lastdrop.amount, 10)
    setLastAmount(lastamount)

    let lastuuid = lastdrop && lastdrop.uuid
    setLastUuid(lastuuid)

    // let cashlist = response.data
    // cashlist = _.map(cashlist, (cl, index) => {
    //   // console.log('-- cl', cl)
    //   // let value = parseInt(cl, 10)
    //   return {
    //     uuid: index,
    //     title: `${parseInt(cl.value, 10)}`,
    //     value: parseInt(cl.value, 10),
    //     count: 0,
    //   }
    // })
    // setChips(cashlist)
    return lastdrop
  }

  const tabulateLastDrop = async (cashlist, lastdrop) => {
    // console.log('-- tabulate last drop', { cashlist, lastdrop })
    let cashinfos = ChipCalculate.detabulateChipsByAmount(cashlist, lastdrop?.amount)
    setChips(cashinfos)
  }

  const deinit = () => {
    source.cancel('api_cancel')
  }

  const onEnterDisabled = useCallback(() => {
    let total = 0
    for (let c in chips) {
      let chip = chips[c]
      if (chip && chip.count > 0) {
        total += chip.count
      }
    }
    return total <= 0
  }, [chips])

  const onEnter = async () => {
    try {
      setLoading(true)
      let total = ChipCalculate.getTotal(chips)
      let response = await onUpdateFastDrop(lastUuid, 'sit', total)
      console.log('fastdrop: sit: add drop response', response)
      setLoading(false)

      // setDialogMessage('Fast dropped successfully.')
      // setDialogMessageVisible(true)
      onMessage('Last drop updated successfully.')

      // Toast.show(`Success`, {
      //   type: 'success',
      // })
    } catch (error) {
      setLoading(false)
      Toast.show(`Failed: ${error && error.message}`, {
        type: 'danger',
      })
    }
  }

  const header = () => {
    return (
      <View style={styles.headerWrap}>
        <Pressable
          style={[styles.buttonBase]}
          onPress={() => setVisible(false)}>
          <Feather name={'arrow-left'} size={24} color={'white'} />
        </Pressable>
        <View style={styles.titleWrap}>
          {
            loading ?
            <ActivityIndicator /> :
            <Text style={styles.titleText}>
              Last Dropped
            </Text>
          }
        </View>
        {/* <Cash scale={1.0} onPress={() => setIsCash(true)}>
          <Text style={styles.cashText}>Note</Text>
        </Cash> */}
      </View>
    )
  }

  return (
    <Dialog
      visible={visible}
      header={header()}
      title={'Last Dropped'}
      minWidth={'80%'}
      minHeight={'80%'}
      setVisible={setVisible}
      style={styles.dialog}>
      <ChipsControl
        chips={chips}
        onChipsChange={setChips}
        onEnterDisabled={onEnterDisabled}
        onEnter={onEnter}
        isCashMode={true}
        summary={(data) => {
          return <DetailView
            style={[styles.summary, cs.pa]}
            model={data}
            labelStyle={{ fontSize: 20 }}
            valueStyle={{ fontSize: 20 }}
            attributes={[
              {
                label: 'Last Drop Amount',
                key: 'last_amount',
                value: `RM ${lastAmount}`,
              },
              {
                label: 'Update Drop Amount',
                key: 'total_chip_amount',
                rowStyle: {
                  paddingBottom: scaleSize(4),
                },
              },
            ]} />
        }}
        styleLeftPanel={{
          backgroundColor: Colors.DASH_TAB_INACTIVE,
        }}
        styleSliderPanel={{
          backgroundColor: Colors.DASH_TAB_INACTIVE,
        }}
      />
    </Dialog>
  )
}

const styles = StyleSheet.create({
  dialog: {
    backgroundColor: Colors.DASH_TAB_INACTIVE,
  },
  // dialog
  headerWrap: {
    flexDirection: 'row',
    width: '100%',
    height: 28 * SC.SCALE,
  },
  titleWrap: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleText: {
    fontSize: 14 * SC.SCALE,
    color: 'white',
  },
  buttonBase: {
    // position: 'absolute',
    // top: 4,
    // right: 4,
    borderRadius: 4,
    padding: 10,
    elevation: 2,
    flexBasis: 24 * SC.SCALE,
    alignItems: 'center',
    justifyContent: 'center',
  },
  cashText: {
    color: 'white',
    position: 'absolute',
    bottom: -10 * SC.SCALE,
  },
  summary: {
    marginTop: SC.GAP * SC.SCALE,
    backgroundColor: Colors.BUTTON_BG,
    borderRadius: 9,
  },
})

DialogDrop.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  refreshCode: PropTypes.any,
  onMessage: PropTypes.func,
}

export default DialogDrop
