import React, {  } from 'react'
// import PropTypes from 'prop-types'
import {
  // Image,
  // SafeAreaView,
  // StatusBar,
  // StyleSheet,
  Text,
  // TouchableOpacity,
  View,
} from 'react-native'
// import { useIsFocused } from '@react-navigation/native'
// import useLoading from '@hooks/useLoading'
// import Log from '@utils/log'

const Home = () => {
  return (
    <View><Text>PITTTTT</Text></View>
  )
}

export default Home
