/* global __DEV__ */
import { /* useSelector, */ useDispatch } from 'react-redux'
import { _onLog } from '@actions/log'

const __DEV__ = true

export class Log {
  static instance = undefined
  static getInstance () {
    if (!Log.instance) {
      Log.instance = new Log()
    }
    return Log.instance
  }

  init () {
    // this.store = store
    this.dispatch = useDispatch()
    this.onLog = _onLog
  }

  static log (message, data) {
    if (__DEV__) {
      console.log(message, data)
    }
    try {
      let log = Log.getInstance()
      if (log.onLog && typeof log.onLog === 'function') {
        log.dispatch(log.onLog('log', message, data))
      }
    } catch (err) {
      console.warn('log: log err', err)
    }
  }

  static info (message, data) {
    if (__DEV__) {
      console.info(message, data)
    }
    try {
      let log = Log.getInstance()
      if (log.onLog && typeof log.onLog === 'function') {
        log.dispatch(log.onLog(log.store, 'info', message, data))
      }
    } catch (err) {
      console.warn('log: info err', err)
    }
  }

  static debug (message, data) {
    if (__DEV__) {
      console.debug(message, data)
    }
    try {
      let log = Log.getInstance()
      if (log.onLog && typeof log.onLog === 'function') {
        log.dispatch(log.onLog(log.store, 'debug', message, data))
      }
    } catch (err) {
      console.warn('log: debug err', err)
    }
  }

  static warn (message, data) {
    if (__DEV__) {
      console.warn(message, data)
    }
    try {
      let log = Log.getInstance()
      if (log.onLog && typeof log.onLog === 'function') {
        log.dispatch(log.onLog(log.store, 'warn', message, data))
      }
    } catch (err) {
      console.warn('log: warn err', err)
    }
  }

  static error (message, data) {
    if (__DEV__) {
      console.error(message, data)
    }
    try {
      let log = Log.getInstance()
      if (log.onLog && typeof log.onLog === 'function') {
        log.dispatch(log.onLog(log.store, 'error', message, data))
      }
    } catch (err) {
      console.warn('log: error err', err)
    }
  }
}

export default Log
