import * as Linking from 'expo-linking'

export default {
  prefixes: [Linking.makeUrl('/')],
  config: {
    screens: {
      // drawer: {
      //   screens: {
      //     splash: 'splash',
      //     home: 'home',
      //   }
      // },
      //
      // // tms
      splash: {
        path: 'splash',
      },
      home: {
        path: 'home', // tms & pit
      },
      'wizard-opening': {
        path: 'wizard/opening',
      },
      'wizard-selecttable': {
        path: 'wizard/select-table',
      },
      'wizard-selectfloat': {
        path: 'wizard/select-float',
      },
      'wizard-enterid': {
        path: 'wizard/enter-id',
      },
      notfound: '*',
    },
  },
}
