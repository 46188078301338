import React, {} from 'react'
import PropTypes from 'prop-types'
import {
  Alert,
  Modal,
  Pressable,
  StyleSheet,
  Text,
  // TouchableOpacity,
  View,
} from 'react-native'
import Feather from '@components/icons/feather'

import Styles from '@styles'
const { SC, Colors } = Styles

const Dialog = (props) => {
  const {
    visible,
    setVisible,
    minWidth,
    minHeight,
    header,
    title,
    children,
    style,
  } = props

  return (
    <Modal
      animationType="fade"
      transparent={true}
      presentationStyle={"overFullScreen"}
      visible={visible}
      onRequestClose={() => {
        Alert.alert('Modal has been closed.')
      }}
    >
      <View style={styles.container}>
        <View style={[styles.modalView, { minWidth: minWidth || '60%', minHeight: minHeight || '40%' }, style]}>
          {
            header == null ?
            <View style={styles.headerWrap}>
              <Text style={styles.headerText}>{title}</Text>
              <Pressable
                style={[styles.buttonClose]}
                onPress={() => setVisible(false)}
              >
                <Feather name={'x-circle'} size={24} color={'white'} />
              </Pressable>
            </View> : header
          }
          <View style={styles.contentWrap}>
            { children ? children : null }
          </View>
        </View>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'rgba(0,0,0,0.4)',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalView: {
    margin: 12 * SC.SCALE,
    backgroundColor: Colors.DASH_MAIN,
    borderRadius: 12 * SC.SCALE,
    borderColor: 'yellow',
    borderWidth: 2,
    // padding: SC.GAP * SC.SCALE,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    flexDirection: 'column',
  },
  headerWrap: {
    height: 14 * SC.SCALE,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerText: {
    fontSize: 14 * SC.SCALE,
    color: 'white',
  },
  buttonClose: {
    position: 'absolute',
    top: 4,
    right: 4,
    borderRadius: 4,
    padding: 10,
    elevation: 2,
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  contentWrap: {
    width: '100%',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    margin: SC.GAP * SC.SCALE,
    marginBottom: 10 * SC.SCALE,
  },
})

Dialog.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  minWidth: PropTypes.string,
  minHeight: PropTypes.string,
  header: PropTypes.any,
  title: PropTypes.string,
  children: PropTypes.any,
  style: PropTypes.any,
}

export default Dialog
