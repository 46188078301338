import React, {
    // useEffect
} from 'react'
import PropTypes from 'prop-types'
import {
    // ActivityIndicator,
    // LogBox,
    // SafeAreaView,
    // StatusBar,
    // StyleSheet,
    // Text,
    // TextInput,
    // TouchableOpacity,
    View,
} from 'react-native'
import LabelValue from './LabelValue'
// import Log from '@utils/log'

// styles
// import Styles from '@styles'
// const { SC, Colors } = Styles

const DetailView = (props) => {
    const {
        model,
        //accept labelStyle, labelStyle, value
        attributes,
        style,
        labelStyle,
        valueStyle,
        rowStyle,
    } = props

    const guessLabel = (attribute) => {
        if (typeof (attribute) === 'string') {
            return attribute.replace(/\_/g, ' ')
        }
        else if (attribute.label !== undefined || attribute.label != null) {
            return attribute.label
        }
        else if (attribute.key) {
            return attribute.key.replace(/\_/g, ' ')
        }

        // if (attribute.key.includes('_')) {
        //     return attribute.label.replace('_', '')
        // }
    }

    const getValue = (attribute) => {

        if (attribute.value) {
            if (typeof attribute.value === 'function') {
                return attribute.value(model)
            }
            return attribute.value
        }
        else if (typeof (attribute) === 'string') {
            return model[attribute]
        }
        if (typeof attribute.key === 'function') {
            return attribute.key(model)
        }
        else {
            return model[attribute.key]
        }
    }

    return (
        <View style={[style]}>
            {
                attributes && attributes.map(attribute => {
                    return (<LabelValue
                        label={guessLabel(attribute)}
                        value={getValue(attribute)}
                        labelStyle={[{ ...labelStyle }, { ...attribute.labelStyle }]}
                        valueStyle={[{ ...valueStyle }, { ...attribute.valueStyle }]}
                        style={[{ ...rowStyle }, { ...attribute.rowStyle }]}
                        key={typeof (attribute) === 'string' ? attribute : attribute.key}
                    />)
                })
            }
            {/* <LabelValue label='Number of game' value='0' labelStyle={labelStyle} valueStyle={valueStyle} style={rowStyle} />
            <LabelValue label='Opening Amount' value='RM 0.00' labelStyle={labelStyle} valueStyle={valueStyle} style={rowStyle} />
            <LabelValue label='Current Float' value='RM 0.00' labelStyle={labelStyle} valueStyle={valueStyle} style={rowStyle} />
            <LabelValue label='Drop' value='RM 0.00' labelStyle={labelStyle} valueStyle={valueStyle} style={rowStyle} />
            <LabelValue label='Win/Lose' value='RM 0.00' labelStyle={labelStyle} valueStyle={valueStyle} style={rowStyle} />
            <LabelValue label='' value='Time/Amount' labelStyle={labelStyle} valueStyle={valueStyle} style={rowStyle} />
            <LabelValue label='Last Drop' value='0:00:00/RM 0.00' labelStyle={labelStyle} valueStyle={valueStyle} style={rowStyle} /> */}
        </View>
    )
}

// const styles = StyleSheet.create({
// row: {

// },
// })

DetailView.propTypes = {
    model: PropTypes.any,
    style: PropTypes.any,
    attributes: PropTypes.any,
    labelValuePairs: PropTypes.array,
    labelStyle: PropTypes.any,
    valueStyle: PropTypes.any,
    rowStyle: PropTypes.any,
}

export default DetailView
export {
    LabelValue,
}