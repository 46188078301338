
export const CLEAR_TABLE = 'CLEAR_TABLE'
export const UPDATE_TABLE = 'UPDATE_TABLE'

export const PARAM_TABLE_UUID = 'uuid'
export const PARAM_TABLE_CHIPS = 'chips'
export const PARAM_TABLE_MIN = 'min'
export const PARAM_TABLE_MAX = 'max'
export const PARAM_TABLE_ROLE_ID = 'roleId'
export const PARAM_TABLE_SESSION_UUID = 'sessionUuid'

export const clearTable = () => ({
  type: CLEAR_TABLE,
})

export const updateTable = (name, value) => ({
  type: UPDATE_TABLE,
  name,
  value,
})
