import Toast from 'react-native-root-toast'

export class ToastModule {
  static exception(e, defaultMessage = 'Exeption') {
    if (e) {
      if (e.data && e.data.errors) {
        this.show(e.data.errors, { type: 'danger' })
      }
      else if (e.message) {
        this.show(e.message, { type: 'danger' })
      }
      else {
        this.show(e, { type: 'danger' })
      }
    }
    else {
      this.show(defaultMessage, { type: 'danger' })
    }
  }

  static success(message) {
    this.show(message, { type: 'success' })
  }

  static denger(message) {
    this.show(message, { type: 'danger' })
  }

  static warning(message) {
    this.show(message, { type: 'warning' })
  }

  static show(message, params = {}) {
    if (typeof message === 'object' &&
      !Object.prototype.hasOwnProperty.call(message, 'length')) {
      // object
      message = JSON.stringify(message)
    } else if (typeof message === 'object') {
      // array
      message = JSON.stringify(message)
    }

    let typeInfo = {}
    switch (params && params.type) {
      case 'danger':
        typeInfo.backgroundColor = '#aa0044'
        break
      case 'warning':
        typeInfo.backgroundColor = '#ffaa00'
        break
      case 'success':
        typeInfo.backgroundColor = '#4EBF39'
        break
      default:
        break
    }

    return Toast.show(message, {
      duration: Toast.durations.SHORT,
      position: Toast.positions.TOP,
      shadow: true,
      animation: true,
      hideOnPress: true,
      delay: 0,
      onShow: () => {
        // nothing
      },
      onShown: () => {
        // nothing
      },
      onHide: () => {
        // nothing
      },
      onHidden: () => {
        // nothing
      },
      ...typeInfo,
      ...params,
    })
  }
}

export default ToastModule
