import Tabs from '@components/tabs'
import { StyleSheet, View, } from 'react-native'


const Header = (props) => {
    const {
        tabs,
        index,
        selectedItem,
        onChangeTab,
    } = props

    return (
        <View style={styles.header}>
            <Tabs
                tabs={tabs}
                index={tabs.findIndex(item => (item.id === selectedItem.id))}
                onChangeTab={(i) => onChangeTab(tabs[i])}
            />
        </View>
    )
}

const styles = StyleSheet.create({
    header: {
        flexDirection: 'row',
    }
})

export default Header