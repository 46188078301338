import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import {
  StyleSheet,
  View,
} from 'react-native'

// import Button from '@components/button'
import Input from '@components/input'
// import Chip from '@components/chip'
import Cash from '@components/cash'
import Slider from '@components/slider'

import Styles from '@styles'
const { SC } = Styles

const CashInputSlider = (props) => {
  const {
    chip,
    min,
    max,
    step,
    onChangeValue,
  } = props

  return (
    <View style={styles.container}>
      {
        (chip && !_.isEmpty(chip)) ?
          <View style={styles.chipWrapper}>
            <Cash
              scale={1.0}
              onPress={() => {
                //
              }}
            />
            <Input
              value={chip.count}
              data={chip}
              style={styles.chipInput}
              onChangeValue={(chip, value) => onChangeValue(chip, value)}
              keyboardType={'numeric'}
            />
          </View> : null
      }
      {
        (chip && !_.isEmpty(chip)) ?
          <Slider
            minimumValue={min}
            maximumValue={max}
            step={step || 1}
            value={chip.count}
            setValue={(value) => onChangeValue(chip, value)}
            style={{}}>
          </Slider>: null
      }
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    justifyContent: 'center',
    alignItems:'center',
  },
  chipWrapper: {
    // width: 50 * SC.SCALE,
    // height: 50 * SC.SCALE,
    // borderRadius: '50%',
    marginLeft: 6 * SC.GAP,
    marginRight: 6 * SC.GAP,
    marginBottom: 6 * SC.GAP,
    marginTop: 0 * SC.GAP,
    // backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  chipInput: {
    position: 'absolute',
    bottom: -14 * SC.SCALE,
  },
})

CashInputSlider.propTypes = {
  chip: PropTypes.any.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  onChangeValue: PropTypes.func,
}

export default CashInputSlider
