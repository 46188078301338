import React, { useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import {
    // ActivityIndicator,
    // SafeAreaView,
    // StatusBar,
    StyleSheet,
    Text,
    // TextInput,
    // TouchableOpacity,
    View,
} from 'react-native'
import Log from '@utils/log'

// styles
import Styles from '@styles'
const { SC } = Styles

const LabelValue = (props) => {
    const {
        label,
        value,
        style,
        labelStyle,
        valueStyle,
    } = props

    const localLabelStyle = useMemo(() => {
        let finalStyle = {}
        if (Array.isArray(labelStyle)) {
            finalStyle = labelStyle.reduce(((r, c) => Object.assign(r, c)), {})
        }
        else {
            finalStyle = labelStyle
        }
        return finalStyle
    }, [labelStyle])

    const localVlueStyle = useMemo(() => {
        let finalStyle = {}
        if (Array.isArray(valueStyle)) {
            finalStyle = valueStyle.reduce(((r, c) => Object.assign(r, c)), {})
        }
        else {
            finalStyle = valueStyle
        }
        return finalStyle
    }, [valueStyle])

    return (
        <View style={[styles.row, style]}>
            <Text style={{ ...styles.label, ...localLabelStyle }}>{label}</Text>
            <Text style={[{ ...styles.value }, { ...localVlueStyle }]}>{value}</Text>
        </View>
    )
}

const styles = StyleSheet.create({
    row: {
        paddingTop: 5 * SC.SCALE,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    label: {
        fontWeight: 'bold',
        textAlign: 'left',
        fontSize: 12 * SC.SCALE,
        color: 'white',
        textTransform: 'capitalize',
    },
    value: {
        textAlign: 'right',
        fontSize: 12 * SC.SCALE,
        color: 'white',
    },
})

LabelValue.propTypes = {
    label: PropTypes.any,
    value: PropTypes.any,
    style: PropTypes.any,
    labelStyle: PropTypes.any,
    valueStyle: PropTypes.any,
}

export default LabelValue
