import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
// import {
//   Platform,
//   // SafeAreaView,
//   // StatusBar,
//   // Text,
//   // TextInput,
//   // TouchableOpacity,
//   // View,
// } from 'react-native'
// import Drawer from '@screens/drawer'
import Log from '@utils/log'
import ExpoConstants from 'expo-constants'
const ExConstants  = ExpoConstants?.expoConfig?.extra

// tms
import Splash from '@screens/tms/splash'
import TmsHome from '@screens/tms/home'
import WizardOpening from '@screens/tms/wiz-opening'
import WizardSelectTable from '@screens/tms/wiz-selecttable'
import WizardSelectFloat from '@screens/tms/wiz-selectfloat'
import WizardEnterId from '@screens/tms/wiz-enterid'

// pit
import PitHome from '@screens/pit/home'

import NotFound from '@screens/notfound'

import Linking from './linking'

// react-redux
import { useSelector/* , useDispatch */ } from 'react-redux'
// import { updateTable } from '@actions/table'
// import { updateToken } from '@actions/auth'

// navigation
import { /* DefaultTheme, */ NavigationContainer, DefaultTheme, DarkTheme } from '@react-navigation/native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
// import { createStackNavigator } from '@react-navigation/stack'

import NavOptions from './navoptions'
const Stack = createNativeStackNavigator()

const headerShown = false // (Platform.OS !== 'web')

const Router = ({ colorScheme }) => {
  // dispatch actions
  // const dispatch = useDispatch()
  const auth = useSelector(state => state.auth)
  const table = useSelector(state => state.table)
  const token = auth && auth.token
  const tuuid = table && table.uuid

  useEffect(() => {
    Log.log('router: process.env', process.env)
    Log.log('router: expoConfig constants', ExConstants)
  }, [])

  return (
    <NavigationContainer
      linking={Linking}
      theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}>
      <Stack.Navigator
        screenOptions={{ headerShadowVisible: true }}
        initialRouteName={(token && token.length > 0 && tuuid && tuuid.length > 0) ? 'home' : 'splash'}>
        {/* TMS */}
        <Stack.Screen
          options={NavOptions.getNavOptions({ headerShown })}
          name='splash'
          component={Splash} />
        <Stack.Screen
          options={NavOptions.getNavOptions({ headerShown })}
          name='home'
          component={TmsHome} />
        <Stack.Screen
          options={NavOptions.getNavOptions({ headerShown })}
          name='wizard-opening'
          component={WizardOpening} />
        <Stack.Screen
          options={NavOptions.getNavOptions({ headerShown })}
          name='wizard-selecttable'
          component={WizardSelectTable} />
        <Stack.Screen
          options={NavOptions.getNavOptions({ headerShown })}
          name='wizard-selectfloat'
          component={WizardSelectFloat} />
        <Stack.Screen
          options={NavOptions.getNavOptions({ headerShown })}
          name='wizard-enterid'
          component={WizardEnterId} />
        {/* PIT */}
        <Stack.Screen
          options={NavOptions.getNavOptions({ headerShown })}
          name='pit-home'
          component={PitHome} />
        {/* <Stack.Screen
          options={NavOptions.getNavOptions({ headerShown })}
          name='drawer'
          component={Drawer} /> */}
        <Stack.Screen
          options={NavOptions.getNavOptions({ headerShown })}
          name='notfound'
          component={NotFound} />
      </Stack.Navigator>
    </NavigationContainer>
  )
}

Router.propTypes = {
  colorScheme: PropTypes.string,
}

export default Router
