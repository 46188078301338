import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {
  FlatList,
  StyleSheet,
  // Text,
  // TouchableOpacity,
  // View,
} from 'react-native'
// import Feather from '@components/icons/feather'
import ListItem from './item'

import Styles from '@styles'
const { Colors } = Styles

const StyledFlatList = (props) => {
  const {
    data,
    dataKey,
    dataInfos,
    index,
    renderItem,
    onPress,
    // children,
    style,
    styleItem,
  } = props

  useEffect(() => {
    console.log('flatlist: init')
  }, [])

  const defaultItem = (iprops) => {
    const {
      item,
    } = iprops
    let i = _.findIndex(data, (find) => find[dataKey || 'id'] === item[dataKey || 'id'])
    return (
      <ListItem
        onPress={onPress}
        data={item}
        dataInfos={dataInfos}
        style={{
          ...styleItem,
          ...(i === index ? { backgroundColor: Colors.BUTTON_ACTIVE } : {}),
        }}
      />
    )
  }

  return (
    <FlatList
      style={[
        styles.container,
        style,
      ]}
      data={data}
      renderItem={renderItem ? renderItem : defaultItem}
      keyExtractor={(item, index) => `${index}`}>
    </FlatList>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    // backgroundColor: 'white',
  },
})

StyledFlatList.propTypes = {
  data: PropTypes.array,
  dataKey: PropTypes.string,
  dataInfos: PropTypes.array,
  index: PropTypes.number,
  renderItem: PropTypes.func,
  onPress: PropTypes.func,
  // children: PropTypes.any,
  style: PropTypes.any,
  styleItem: PropTypes.any,
}

export default StyledFlatList
