import {
    StyleSheet,
    // Text,
    // TouchableOpacity,
    // View,
    // Image,
} from 'react-native'
import React, {
    useEffect,
    useState,
} from 'react'
// import Log from '@utils/log'
// import Feather from '@components/icons/feather'
import PropTypes from 'prop-types'
import ChipsControl from '@components/chipscontrol'
import Toast from '@components/toast'
import DetailView from '@components/detailview'

import axios from 'axios'
import ApiFillCredit from '@apis/fillcredit'
import { useSelector } from 'react-redux'
import useLoading from '@hooks/useLoading'

import Styles, {
    CommonStyle as cs,
    scaleSize,
} from '@styles'
const { SC, Colors } = Styles



const Fill = (props) => {

    const table = useSelector(state => state.table)
    const tableUuid = table && table.uuid
    const source = axios.CancelToken.source()

    const {
        chips,
    } = props

    const [fillChips, setFillChips] = useState([])
    const [loading, setLoading] = useLoading()

    useEffect(() => {
        setFillChips(chips.map(chip => { return { ...chip } }))
    }, [chips])

    const onEnter = async () => {
        setLoading(true)

        try {
            const chipsQty = fillChips
                .filter(item => item.count > 0)
                .map((item) => {
                    return {
                        chip_uuid: item.uuid,
                        quantity: item.count,
                    }
                })

            await ApiFillCredit.createFill(
                source.token,
                tableUuid,
                chipsQty,
            )

            chips.forEach(item => item.count = 0)
            setFillChips([...chips])

            Toast.success(`Fill Chips Successful`)
        } catch (e) {
            Toast.exception(e, 'Fill Chips Failed')
        }
        setLoading(false)
    }

    return (
        <ChipsControl
            chips={fillChips}
            onChipsChange={setFillChips}
            onEnter={onEnter}
            summary={(data) => {
                return <DetailView
                    style={[styles.summary, cs.pa]}
                    model={data}
                    labelStyle={{ fontSize: 20 }}
                    valueStyle={{ fontSize: 20 }}
                    attributes={[
                        {
                            label: 'Fill Amount',
                            key: 'fill_amount',
                            value: '',
                        },
                        {
                            label: '',
                            rowStyle: { paddingTop: scaleSize(20) },
                            key: 'total_chip_amount',
                        },
                    ]} />
            }}
        />
    )
}

const styles = StyleSheet.create({
    summary: {
        marginTop: SC.GAP * SC.SCALE,
        backgroundColor: Colors.BUTTON_BG,
        borderRadius: 9,
    },
})

Fill.propTypes = {
    chips: PropTypes.any,
}

export default Fill