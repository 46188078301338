import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import {
  StyleSheet,
  // Text,
  TouchableOpacity,
  View,
} from 'react-native'

import Slider from '@react-native-community/slider'
import Feather from '@components/icons/feather'

import Styles from '@styles'
const { SC } = Styles

const SliderControl = (props) => {
  const {
    minimumValue,
    maximumValue,
    step,
    value,
    setValue,
    style,
  } = props

  // const [value, setValue] = useState(0)

  const onStep = useCallback((step) => {
    let val = value
    val += step
    if (val < minimumValue) {
      val = minimumValue
    }
    if (val > maximumValue) {
      val = maximumValue
    }
    if (setValue) setValue(val)
  }, [value, setValue, minimumValue, maximumValue])

  return (
    <View style={[styles.container, style]}>
      <View style={styles.sideWrapper}>
        <TouchableOpacity style={styles.button} onPress={() => onStep(-step)}>
          <Feather name={'minus'}/>
        </TouchableOpacity>
      </View>
      <View style={styles.sliderWrapper}>
        <Slider
          style={styles.slider}
          minimumValue={minimumValue}
          maximumValue={maximumValue}
          step={step}
          value={value}
          onValueChange={value => ((setValue) ? setValue(value) : () => {
            // do nothing
          })}
          minimumTrackTintColor="#000000"
          maximumTrackTintColor="#000000"
          thumbTintColor="#ffffff"
        />
      </View>
      <View style={styles.sideWrapper}>
        <TouchableOpacity style={styles.button} onPress={() => onStep(step)}>
          <Feather name={'plus'}/>
        </TouchableOpacity>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    width: '100%',
  },
  sideWrapper: {
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  sliderWrapper: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  slider: {
    width: '100%',
  },
  button: {
    borderRadius: 9,
    backgroundColor: 'white',
    alignItems: 'center',
    padding: 3 * SC.SCALE,
    paddingLeft: 6 * SC.SCALE,
    paddingRight: 6 * SC.SCALE,
    margin: 2 * SC.SCALE,
  },
})

SliderControl.propTypes = {
  minimumValue: PropTypes.number,
  maximumValue: PropTypes.number,
  step: PropTypes.number,
  value: PropTypes.number,
  setValue: PropTypes.func,
  style: PropTypes.any,
}

export default SliderControl
