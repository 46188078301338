import api from '@apis'

// unauthenticated

const login = (cancelToken, credential) => {
  var data = {
    credential,
  }
  return api({
    url: `/api/player/login`,
    method: 'post',
    data,
    cancelToken,
  })
}

const createGuest = (cancelToken, tableUuid) => {
  var data = {
    'table_uuid': tableUuid,
  }
  return api({
    url: `/api/player/guest/add`,
    method: 'post',
    data,
    cancelToken,
  })
}

const suspend = (cancelToken, playerUuid) => {
  var data = {
    'player_uuid': playerUuid,
  }
  return api({
    url: `/api/player/suspend`,
    method: 'post',
    data,
    cancelToken,
  })
}

const resume = (cancelToken, playerUuid) => {
  var data = {
    'player_uuid': playerUuid,
  }
  return api({
    url: `/api/player/resume`,
    method: 'post',
    data,
    cancelToken,
  })
}

const checkout = (cancelToken, playerUuid) => {
  var data = {
    'player_uuid': playerUuid,
  }
  return api({
    url: `/api/table/player/position/checkout`,
    method: 'post',
    data,
    cancelToken,
  })
}

const detail = (cancelToken, playerUuid) => {
  var data = {
    'player_uuid': playerUuid,
  }
  return api({
    url: `/api/player/details`,
    method: 'post',
    data,
    cancelToken,
  })
}

// authenticated

export default {
  login,
  createGuest,
  suspend,
  resume,
  detail,
  checkout,
}
