import React, { /* useEffect */ } from 'react'
import PropTypes from 'prop-types'
import {
  Image,
  // Platform,
  // StatusBar,
  StyleSheet,
  Text,
  View,
} from 'react-native'
import Log from '@utils/log'

import ButtonHeader from '@components/buttonheader'
import Feather from '@components/icons/feather'

// import Constants from '@constants'
import NavConstants from './constants'

import Styles from '@styles'
const { SC } = Styles

import HeaderLogo from '@assets/header_logo.png'

const NavHeader = (props) => {
  const {
    // route,
    // navigation,
    isButtonCheck,
    isButtonDisabled,
    onHeaderButtonPressed,
  } = props

  // useEffect(() => {
  //   Log.log('-- StyleConstants', StyleConstants)
  // }, [])

  const isButton = (name) => {
    if (isButtonCheck) return isButtonCheck(name)
    return false
  }

  const isDisabled = (name) => {
    if (isButtonDisabled) return isButtonDisabled(name)
    return false
  }

  const onButtonPressed = (info) => {
    if (!onHeaderButtonPressed) {
      Log.error('onHeaderButtonPressed not implemented in base screen class')
      // throw new Error('onHeaderButtonPressed not implemented in base screen class')
      return
    }
    return onHeaderButtonPressed(info)
  }

  const ButtonStub = () => {
    return (
      <View style={[styles.button, styles.buttonStub]}></View>
    )
  }

  const renderHeaderButton = (name, title, iconName) => {
    if (!isButton(name)) {
      return (
        <ButtonStub />
      )
    }
    return (
      <View style={{ justifyContent: 'center', alignItems: 'center' }}>
        <ButtonHeader
          onPress={() => onButtonPressed({ name })}
          disabled={isDisabled(name)}
          style={styles.button}>
          <Feather
            name={iconName}
            size={15 * SC.SCALE}
            style={[
              styles.icon,
              isDisabled(name) ? styles.iconDisabled : {},
            ]}>
          </Feather>
        </ButtonHeader>
        <Text
          style={[
            styles.title,
            isDisabled(name) ? styles.titleDisabled : {},
          ]}>
          {title}
        </Text>
      </View>

    )
  }

  return (
    <View style={styles.container}>
      <View style={styles.top}></View>
      <View style={styles.bottom}>
        <View style={styles.side}>
          <View style={[styles.buttonWrapper, { marginLeft: 10 * SC.SCALE }]}>
            {renderHeaderButton('back', 'Back', 'arrow-left')}
            {renderHeaderButton('home', 'Home', 'home')}
          </View>
        </View>
        <View style={styles.center}>
          <View style={styles.logoWrapper}>
            <Image style={styles.logo} source={HeaderLogo}></Image>
          </View>
        </View>
        <View style={styles.side}>
          <View style={[styles.buttonWrapper, { marginRight: 10 * SC.SCALE }]}>
            {renderHeaderButton('closing', 'Closing', 'target')}
            {renderHeaderButton('account', 'Account', 'user')}
          </View>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    height: (NavConstants.HEIGHT) * SC.SCALE_V, // custom height
    // paddingTop: NavConstants.HEIGHT_TOP, // padding to prevent intersect nav top
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor: 'red',
    // flex: 1,
    position: 'relative',
    marginTop: 22,
  },
  top: {
    height: NavConstants.HEIGHT_TOP,
    width: '100%',
  },
  bottom: {
    height: NavConstants.HEIGHT * SC.SCALE_V,
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  center: {
    height: NavConstants.HEIGHT * SC.SCALE_V,
    flexGrow: 1,
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoWrapper: {
    flex: 1,
    width: '100%',
    height: '100%',
  },
  logo: {
    // width: '100%',
    height: '100%',
    resizeMode: 'contain',
    // maxWidth: 200,
    // maxHeight: '100%',
    // aspectRatio: 4,
    // aspectRatio: 1,
    // ...(Platform.OS === 'web' ? { aspectRatio: 1 } : {}),
  },
  side: {
    height: NavConstants.HEIGHT,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonWrapper: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    paddingTop: 10 * SC.SCALE,
    paddingBottom: 10 * SC.SCALE,
  },
  button: {
    // margin: 10 * SC.SCALE,
    marginBottom: 2 * SC.SCALE,
    marginLeft: 4 * SC.SCALE,
    marginRight: 4 * SC.SCALE,
  },
  buttonStub: {
    width: 30 * SC.SCALE,
    height: 30 * SC.SCALE,
    borderRadius: 60 * SC.SCALE,
    borderWidth: 2 * SC.SCALE,
    borderColor: 'transparent',
  },
  icon: {
    color: 'white',
  },
  iconDisabled: {
    color: 'gray',
  },
  title: {
    color: 'white',
    fontSize: 20,
  },
  titleDisabled: {
    color: 'gray',
  },
})

NavHeader.propTypes = {
  // navigation: PropTypes.any,
  // route: PropTypes.any,
  isButtonCheck: PropTypes.func,
  isButtonDisabled: PropTypes.func,
  onHeaderButtonPressed: PropTypes.func,
}

export default NavHeader
