import React, { } from 'react'
import PropTypes from 'prop-types'
import {
  Pressable,
  StyleSheet,
  // Text,
  // TouchableOpacity,
  // View,
} from 'react-native'
import Feather from '@components/icons/feather'

import Styles from '@styles'
const { SC } = Styles

const Cash = (props) => {
  const {
    scale,
    onPress,
    style,
    children,
  } = props
  return (
    <Pressable
      style={[
        styles.container,
        style,
        {
          flexBasis: scale * 24 * SC.SCALE,
          marginVertical: scale * 6 * SC.SCALE,
          marginHorizontal: scale * 4 * SC.SCALE,
          paddingVertical: scale * 2 * SC.SCALE,
          paddingHorizontal: scale * 12 * SC.SCALE,
        },
      ]}
      onPress={onPress}>
      <Feather name={'dollar-sign'} size={24} color={'white'} />
      {
        children ? children : null
      }
    </Pressable>
  )
}

const styles = StyleSheet.create({
  container: {
    borderRadius: 4,
    elevation: 2,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 2,
    borderColor: 'white',
    // flexBasis: 24 * SC.SCALE,
    // marginVertical: 6 * SC.SCALE,
    // marginHorizontal: 4 * SC.SCALE,
    // paddingVertical: 2 * SC.SCALE,
    // paddingHorizontal: 12 * SC.SCALE,
  },
})

Cash.propTypes = {
  scale: PropTypes.number,
  onPress: PropTypes.func,
  style: PropTypes.any,
  children: PropTypes.any,
}

export default Cash
