import React, {
  // useEffect,
  useCallback,
  // useState,
  useRef,
} from 'react'
import PropTypes from 'prop-types'
// import _ from 'lodash'
import axios from 'axios'
import {
  // ActivityIndicator,
  // Pressable,
  StyleSheet,
  Text,
  // TouchableOpacity,
  View,
} from 'react-native'
import Button from '@components/button'
import Dialog from '@components/dialog'
import LoginView from '@screens/base/loginview'
import LoadingView from '@components/loadingview'

import Log from '@utils/log'
// import { useIsFocused } from '@react-navigation/native'
import useLoading from '@hooks/useLoading'

// import { useSelector } from 'react-redux'

import Styles from '@styles'
const { SC, Colors } = Styles

import ApiUser from '@apis/user'

const DialogLock = (props) => {
  const {
    visible,
    setVisible,
    onReset,
  } = props
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, onLoading] = useLoading()
  const source = axios.CancelToken.source()

  // useEffect(() => {

  // }, [])

  const loginRef = useRef()

  const onLogin = useCallback(async (username, password) => {
    // Log.log('dialoglock: on login', { username, password })
    try {
      onLoading(true)
      let response = await ApiUser.login(source.token, username, password)
      Log.log('dialoglock: login response', response)
      setVisible(false)
      onLoading(false)
    } catch (error) {
      onLoading(false)
    }
  }, [])

  const onLoginRef = useCallback(async () => {
    let username = loginRef?.current?.username
    let password = loginRef?.current?.password
    await onLogin(username, password)
  }, [onLogin])

  const onResetRef = useCallback(() => {
    setVisible(false)
    onReset()
  }, [onReset])

  return (
    <Dialog
      visible={visible}
      minWidth={'90%'}
      minHeight={'90%'}
      setVisible={setVisible}
      style={styles.dialog}
      header={(
        <View></View>
      )}>
      <View style={[styles.wrapper]}>
        <View style={styles.loginview}>
          <Text style={styles.title}>Screen Locked!</Text>
          <LoginView
            ref={loginRef}
            noButton={true}
            onLogin={onLogin}
            buttonTitle={'Unlock'} />
        </View>
        <View style={styles.buttons}>
          <Button
            title={'Unlock'}
            onPress={() => onLoginRef()}
            style={styles.buttonReset} />
          <Button
            title={'Reset Table'}
            onPress={() => onResetRef()}
            style={styles.buttonDanger} />
        </View>
      </View>
      {
        loading ?
        <View style={styles.loader}>
          <LoadingView />
        </View> : null
      }
    </Dialog>
  )
}

const styles = StyleSheet.create({
  dialog: {
    backgroundColor: Colors.DASH_BG,
  },
  // content
  title: {
    fontSize: 12 * SC.SCALE,
    color: 'white',
  },
  wrapper: {
    // ...StyleSheet.absoluteFill,
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor: 'white',
    // display: 'flex',
    // flexDirection: 'row',
    flexDirection: 'column',
  },
  loginview: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttons: {
    flexDirection: 'row',
  },
  buttonReset: {
    marginTop: SC.GAP * SC.SCALE,
    backgroundColor: Colors.BUTTON_ACTIVE,
    marginRight: SC.GAP_S * SC.SCALE,
  },
  buttonDanger: {
    marginTop: SC.GAP * SC.SCALE,
    backgroundColor: 'red',
    paddingVertical: 3 * SC.SCALE,
  },
  loader: {
    ...StyleSheet.absoluteFill,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255,255,255,0.5)',
  },
})

DialogLock.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  onReset: PropTypes.func,
}

export default DialogLock
