import api from '@apis'

// unauthenticated

const getAll = (cancelToken, pitUuid) => {
  var data = {
    'pit_uuid': pitUuid,
  }
  return api({
    url: '/api/table/all',
    method: 'post',
    data,
    cancelToken,
  })
}

const get = (cancelToken, tableUuid) => {
  var data = {
    'table_uuid': tableUuid,
  }
  return api({
    url: `/api/table`,
    method: 'post',
    data,
    cancelToken,
  })
}

const getBySerialCode = (cancelToken, serialCode) => {
  var data = {
    'serial_code': serialCode,
  }
  return api({
    url: `/api/table/serial-code`,
    method: 'post',
    data,
    cancelToken,
  })
}

const getMinMax = (cancelToken, tableUuid) => {
  var data = {
    'table_uuid': tableUuid,
  }
  return api({
    url: `/api/bet/min-max/all`,
    method: 'post',
    data,
    cancelToken,
  })
}

const getFloat = (cancelToken, tableUuid) => {
  var data = {
    'table_uuid': tableUuid,
  }
  return api({
    url: `/api/table/float`,
    method: 'post',
    data,
    cancelToken,
  })
}

const setFloat = (cancelToken, tableUuid, amount) => {
  var data = {
    'table_uuid': tableUuid,
    'adjustment_amount': amount,
  }
  return api({
    url: `/api/chip/adjustment/add`,
    method: 'post',
    data,
    cancelToken,
  })
}

const open = (cancelToken, tableUuid, minBet, maxBet, users = [], chips = []) => {
  if (chips.length <= 0) throw new Error('invalid chips length')
  var data = {
    'table_uuid': tableUuid,
    'min_bet': minBet,
    'max_bet': maxBet,
    'users': users, // ['user_id', 'role_id']
    'chips': chips, // ['chip_uuid', 'quantity']
  }
  return api({
    url: `/api/table/open`,
    method: 'post',
    data,
    cancelToken,
  })

  // response:
  // "data": {
  //   "table_session_uuid": "1ff1275d-0e10-4138-b36b-950a1bdf06bd",
  //   "table_uuid": "98e25160-e2d2-4a02-8d75-66c0bc6c8f33",
  //   "table_no": "RL01",
  //   "min_bet": 10,
  //   "max_bet": 100,
  //   "float_value": 60, x
  //   "float_type": "Fixed", x
  //   "users": [{
  //     "user_id" : 38,
  //     "role_id" : 5
  //   },{
  //     "user_id" : 42,
  //     "role_id" : 4
  //   }],
  //   "chips": [
  //       {
  //           "chip_uuid": "641843d6-760e-11ed-8e94-4b53bfc498ad",
  //           "quantity": 2
  //       },
  //       {
  //           "chip_uuid": "a52567ff-2dec-4b47-b124-2c3c274a6b81",
  //           "quantity": 2
  //       }
  //   ]
  // }
}

// authenticated

const assign = (cancelToken, tableUuid, roleId) => {
  if (tableUuid == null || tableUuid.length <= 0) throw new Error('invalid table UUID')
  if (roleId == null || roleId.length <= 0) throw new Error('invalid role ID')
  var data = {
    'table_uuid': tableUuid,
    'role_id': roleId,
  }
  return api({
    url: `/api/user/table-session/assign`,
    method: 'post',
    data,
    cancelToken,
  })

  // // response
  // return {
  //   "data": {
  //       "uuid": "2fc593aa-c553-4444-9a9d-d21b4fb5e650",
  //       "table_session_uuid": "0e3e3190-569e-45a8-9554-84ffbba67136",
  //       "table_no": "RL01",
  //       "employee_id": 42,
  //       "role_id": 4,
  //       "role": "Manager",
  //   },
  // }
}

const getOpenTime = (cancelToken, tableUuid) => {
  if (tableUuid == null || tableUuid.length <= 0) throw new Error('invalid table UUID')
  var data = {
    'table_uuid': tableUuid,
  }
  return api({
    url: `/api/table/open/time`,
    method: 'post',
    data,
    cancelToken,
  })
}

const getOverview = (cancelToken, tableUuid) => {
  if (tableUuid == null || tableUuid.length <= 0) throw new Error('invalid table UUID')
  var data = {
    'table_uuid': tableUuid,
  }
  return api({
    url: `/api/table/overview`,
    method: 'post',
    data,
    cancelToken,
  })
}

const addTips = (cancelToken, tableUuid, amount) => {
  if (tableUuid == null || tableUuid.length <= 0) throw new Error('invalid table UUID')
  if (amount == null) throw new Error('invalid amount')
  var data = {
    'table_uuid': tableUuid,
    amount,
  }
  return api({
    url: `/api/tips/add`,
    method: 'post',
    data,
    cancelToken,
  })
}

//position => sit/stand
const getSeat = (cancelToken, tableUuid, position) => {
  var data = {
    'table_uuid': tableUuid,
    'position': position,
  }
  return api({
    url: `/api/table/players/position`,
    method: 'post',
    data,
    cancelToken,
  })
}

const getTotalPlayer = (cancelToken, tableUuid) => {
  var data = {
    'table_uuid': tableUuid,
  }
  return api({
    url: `/api/table/players/total`,
    method: 'post',
    data,
    cancelToken,
  })
}

const assignSeat = (cancelToken, tableUuid, playerUuid, position, seatNo) => {
  var data = {
    'table_uuid': tableUuid,
    'player_uuid': playerUuid,
    'position': position,
    'seat_no': seatNo,
  }
  return api({
    url: `/api/table/player/position/add`,
    method: 'post',
    data,
    cancelToken,
  })
}

const checkoutSeat = (cancelToken, tableUuid, playerUuid) => {
  var data = {
    'table_uuid': tableUuid,
    'player_uuid': playerUuid,
  }
  return api({
    url: `/api/table/player/position/checkout`,
    method: 'post',
    data,
    cancelToken,
  })
}

export default {
  getAll,
  get,
  getBySerialCode,
  getMinMax,
  getFloat,
  setFloat,
  open,
  assign,
  getOpenTime,
  getOverview,
  addTips,
  getSeat,
  getTotalPlayer,
  assignSeat,
  checkoutSeat,
}
