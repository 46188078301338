import React, {
  // useEffect,
  useCallback,
  // useState,
  useRef,
} from 'react'
import PropTypes from 'prop-types'
// import _ from 'lodash'
import axios from 'axios'
import {
  // ActivityIndicator,
  // Pressable,
  StyleSheet,
  Text,
  // TouchableOpacity,
  View,
} from 'react-native'
import Button from '@components/button'
import Dialog from '@components/dialog'
import LoginView from '@screens/base/loginview'
import Toast from '@components/toast'
import LoadingView from '@components/loadingview'

import Log from '@utils/log'
// import { useIsFocused } from '@react-navigation/native'
import useLoading from '@hooks/useLoading'

import { useSelector, useDispatch } from 'react-redux'
import { clearAuth, updateToken, updateName } from '@actions/auth'

import Styles from '@styles'
const { SC, Colors } = Styles

import ApiUser from '@apis/user'

const DialogAccount = (props) => {
  const {
    visible,
    setVisible,
  } = props
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, onLoading] = useLoading()
  const source = axios.CancelToken.source()
  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth)
  const token = auth && auth.token
  const username = auth && auth.name

  const loginRef = useRef()

  const onLogin = useCallback(async (username, password) => {
    // Log.log('DialogAccount: on login', { username, password })
    try {
      onLoading(true)
      let response = await ApiUser.login(source.token, username, password)
      if (!response || !response.data || !response.data.access_token) {
        throw new Error('invalid login data')
      }
      let data = response.data
      let token = data.access_token
      await dispatch(updateToken(token))
      await dispatch(updateName(data?.name))
      Log.log('DialogAccount: login response', response)
      Toast.show('Login successfully.')
      onLoading(false)
    } catch (error) {
      onLoading(false)
    }
  }, [])

  const onLoginRef = useCallback(async () => {
    let username = loginRef?.current?.username
    let password = loginRef?.current?.password
    await onLogin(username, password)
  }, [onLogin])

  const onLogoutRef = useCallback(async () => {
    try {
      onLoading(true)
      Log.log('DialogAccount: clear auth')
      await dispatch(clearAuth())
      Toast.show('Logout successfully.')
      onLoading(false)
    } catch (error) {
      onLoading(false)
    }
  }, [])

  return (
    <Dialog
      visible={visible}
      minWidth={'60%'}
      minHeight={'60%'}
      setVisible={setVisible}
      style={styles.dialog}>
      <View style={[styles.wrapper]}>
        <View style={styles.loginview}>
          <Text style={styles.title}>
            {(token == null || token.length <= 0) ? 'Login' : `Logout '${username}'`} / Switch Account
          </Text>
          {
            (token == null || token.length <= 0) ?
            <LoginView
              ref={loginRef}
              noButton={true}
              onLogin={onLogin}
              buttonTitle={'Login'} /> : null
          }
        </View>
        <View style={styles.buttons}>
          {
            (token == null || token.length <= 0) ?
            <Button
              title={'Login'}
              onPress={() => onLoginRef()}
              style={styles.buttonReset} /> :
            <Button
              title={'Logout'}
              onPress={() => onLogoutRef()}
              style={styles.buttonDanger} />
          }
        </View>
      </View>
      {
        loading ?
        <View style={styles.loader}>
          <LoadingView />
        </View> : null
      }
    </Dialog>
  )
}

const styles = StyleSheet.create({
  dialog: {
    backgroundColor: Colors.DASH_BG,
  },
  // content
  title: {
    fontSize: 12 * SC.SCALE,
    color: 'white',
  },
  wrapper: {
    // ...StyleSheet.absoluteFill,
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor: 'white',
    // display: 'flex',
    // flexDirection: 'row',
    flexDirection: 'column',
  },
  loginview: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttons: {
    flexDirection: 'row',
  },
  buttonReset: {
    marginTop: SC.GAP * SC.SCALE,
    backgroundColor: Colors.BUTTON_ACTIVE,
    marginRight: SC.GAP_S * SC.SCALE,
  },
  buttonDanger: {
    marginTop: SC.GAP * SC.SCALE,
    backgroundColor: Colors.BUTTON_ACTIVE,
    paddingVertical: 3 * SC.SCALE,
  },
  loader: {
    ...StyleSheet.absoluteFill,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255,255,255,0.5)',
  },
})

DialogAccount.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
}

export default DialogAccount
