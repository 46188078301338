import _ from 'lodash'
import {
  CLEAR_TABLE,
  UPDATE_TABLE,
} from '@actions/table'
import {
  PARAM_TABLE_UUID,
  PARAM_TABLE_CHIPS,
  PARAM_TABLE_MIN,
  PARAM_TABLE_MAX,
  PARAM_TABLE_ROLE_ID,
  PARAM_TABLE_SESSION_UUID,
} from '@actions/table'

const tableDefault = _.assign({}, {
  uuid: undefined,
  chips: [],
  min: undefined,
  max: undefined,
  roleId: undefined,
  sessionUuid: undefined,
})

const tableReducer  = (table = tableDefault, action) => {
  // console.log('-- action type', action.type)
  switch (action.type) {
    case CLEAR_TABLE:
      return _.assign({}, tableDefault)
    case UPDATE_TABLE: {
      let name = action.name
      switch (name) {
        case PARAM_TABLE_UUID:
        case PARAM_TABLE_CHIPS:
        case PARAM_TABLE_MIN:
        case PARAM_TABLE_MAX:
        case PARAM_TABLE_ROLE_ID:
        case PARAM_TABLE_SESSION_UUID: {
          if (name === PARAM_TABLE_CHIPS &&
            !Array.isArray(action.value)) {
            throw new Error('invalid chips array')
          }
          let state = {
            ...table,
          }
          state[name] = action.value
          return state
        }
        default:
          break
      }
      break
    }
    default:
      break
  }
  return table
}

export default tableReducer
