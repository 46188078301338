import React, { } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {
  StyleSheet,
  // TextInput,
  Text,
  View,
} from 'react-native'

import Chip from '@components/chip'

import Styles from '@styles'
const { SC } = Styles

const ReqChips = (props) => {
  const {
    reqChips,
    onPress,
    // onChangeValue,
  } = props
  return (
    <View style={styles.container}>
      {
        reqChips ?
          _.map(reqChips, (reqChip, index) => {
            return (
              <View style={styles.chipWrapper} key={`${index}`}>
                <Chip
                  title={reqChip.chip.title}
                  size={15}
                  image={reqChip.chip.image}
                  color={reqChip.chip.color}
                  colorBg={reqChip.chip.colorBg}
                  colorText={reqChip.chip.colorText}
                  onPress={() => onPress(reqChip.chip, index)}
                  styleText={{ fontSize: 12 }}
                  style={styles.chip}
                />
                <Text style={{ color: 'white', fontSize: 25, fontWeight: 'bold', marginStart: 5 }}>
                  x {reqChip.quantity}
                </Text>
              </View>
            )
          }) : null
      }
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    // width: '100%',
    // flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'row',
    paddingTop: 3 * SC.GAP,
  },
  chipWrapper: {
    // width: 50 * SC.SCALE,
    // height: 50 * SC.SCALE,
    // borderRadius: '50%',
    marginHorizontal: 6 * SC.GAP,
    marginTop: 0 * SC.GAP,
    flexDirection: 'row',
    // backgroundColor: 'white',
    // justifyContent: 'flex-start',
    alignItems: 'center',
    // position: 'relative',
  },
  chip: {
    // position: 'relative',
  },
})

ReqChips.propTypes = {
  // chips: PropTypes.array.isRequired,
  onPress: PropTypes.func,
  onChangeValue: PropTypes.func,
  reqChips: PropTypes.any,
}

export default ReqChips
