import React, {
  forwardRef,
  useEffect,
  useCallback,
  useState,
  useMemo,
} from 'react'
// import PropTypes from 'prop-types'
import _ from 'lodash'
import axios from 'axios'
import {
  // Alert,
  // Modal,
  // Platform,
  // Pressable,
  // SafeAreaView,
  // StatusBar,
  StyleSheet,
  // Text,
  // TextInput,
  // TouchableOpacity,
  View,
} from 'react-native'
// import MaterialIcons from '@expo/vector-icons/MaterialIcons'
import { useIsFocused } from '@react-navigation/native'

// import Constants from '@constants'
import Log from '@utils/log'
import ChipCalculate from '@components/chips/calculate'

// import Button from '@components/button'
// import Dialog from '@components/dialog'
import Toast from '@components/toast'
// import Tabs from '@components/tabs'
// import Input from '@components/input'
// import Chip from '@components/chip'
// import Chips from '@components/chips'
// import Slider from '@components/slider'
// import ChipInputSlider from '@screens/base/chipinputslider'
// import DialogMessage from '@screens/base/dialogmessage'

import ChipsInfoPanel from './layout/panel'
import ChipsLayout from './layout/chips'

import DialogUpdate from './layout/dialogupdate'

import Styles from '@styles'
const { SC, Colors } = Styles

// react-redux
import { useSelector /* , useDispatch */ } from 'react-redux'
// import { PARAM_TABLE_CHIPS, updateTable } from '@actions/table'
import useLoading from '@hooks/useLoading'

// import ApiPit from '@apis/pit'
import ApiChip from '@apis/chip'
import ApiTable from '@apis/table'

const ChipsManagement = forwardRef((/* props */) => {
  // const {
  //   // navigation,
  //   setLoading,
  // } = props
  const [loading, setLoading] = useLoading()
  const isFocused = useIsFocused()
  const source = axios.CancelToken.source()

  // dispatch actions
  // const dispatch = useDispatch()

  const table = useSelector(state => state.table)
  const tableUuid = table && table.uuid
  // const tableChips = table && table.chips

  // init
  const [tabs, setTabs] = useState([{
    id: 1,
    name: 'cash',
    label: 'Cash Chips',
    chips: [],
    // chips: [{
    //   uuid: '123',
    //   colour: 'Blue',
    //   value: 10,
    //   count: 0,
    // }, {
    //   uuid: '124',
    //   colour: 'Black',
    //   value: 20,
    //   count: 0,
    // }],
  }, {
    id: 2,
    name: 'nn',
    label: 'NN Chips',
    chips: [],
    // chips: [{
    //   uuid: '123',
    //   colour: 'Blue',
    //   value: 10,
    //   count: 0,
    // }, {
    //   uuid: '124',
    //   colour: 'Red',
    //   value: 50,
    //   count: 0,
    // }],
  }])
  // const [tabIndex/* , setTabIndex */] = useState(0)
  // const [chipIndex, setChipIndex] = useState(0)

  const [chips, setChips] = useState([{
    uuid: '123',
    colour: 'Blue',
    value: 10,
    count: 0,
  }, {
    uuid: '124',
    colour: 'Black',
    value: 20,
    count: 0,
  }])

  const [floatInfo, setFloatInfo] = useState({})

  const total = useMemo(() => {
    return ChipCalculate.getTotal(chips)
  }, [chips])

  const difference = useMemo(() => {
    let floatTotal = floatInfo?.total_float
    let currTotal = total
    return currTotal - floatTotal
  }, [floatInfo, chips])

  const [dialogUpdateVisible, setDialogUpdateVisible] = useState(false)
  // const [dialogMessage, setDialogMessage] = useState('')
  // const [dialogStatus, setDialogStatus] = useState()

  useEffect(() => {
    Log.log('chipsmanagement: init', { isFocused })
    if (isFocused) {
      init()
    }
    return deinit
  }, [isFocused])

  const init = useCallback(async () => { // todo: implement
    Log.log('chipsmanagement: init')
    try {
      setLoading(true)
      // API calls here
      let promises = []
      let promise
      // promise = getTables()
      // promises.push(promise)
      promise = getChipsetsAndChips(tableUuid)
      promises.push(promise)
      promise = getFloat(tableUuid)
      promises.push(promise)
      let responses = await Promise.all(promises)
      let [chipset, finfo] = responses
      tabulateChipsets(chipset, finfo)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      Toast.show(`Init error: ${error}`)
    }
  }, [tabs, setTabs])

  const deinit = useCallback(() => {
    source.cancel('api_cancel')
  }, [source])

  // const getTables = useCallback(async () => {
  //   let response = await ApiPit.getPitUuid(source.token, Constants.SERIAL_CODE)
  //   if (!response || !response.data || !response.data.uuid) {
  //     throw new Error('invalid response or data or uuid')
  //   }

  //   let pitUuid = response.data.uuid
  //   response = await ApiTable.getAll(source.token, pitUuid)
  //   Log.log('chipsmanagement: getTables', response)
  //   return pitUuid
  // }, [])

  const getChipsetsAndChips = async (tableUuid) => {
    if (!tableUuid || tableUuid.length <= 0) {
      throw new Error('invalid table UUID')
    }
    let response
    // response = await ApiPit.getPitUuid(source.token, Constants.SERIAL_CODE)
    // if (!response || !response.data || !response.data.uuid) {
    //   throw new Error('invalid response or data or uuid')
    // }

    // let pitUuid = response.data.uuid
    // response = await ApiTable.getAll(source.token, pitUuid)
    // console.log('-- all tables', response)
    response = await ApiChip.getChipsets(source.token, tableUuid)
    Log.log(`wizard-opening: all chipsets: ${tableUuid}`, response)
    let chipsets = (response && response.data) || []

    let promises = []
    let promise
    for (let c in chipsets) {
      let chipset = chipsets[c]
      let chipUuid = chipset.uuid
      promise = ApiChip.getChips(source.token, chipUuid)
      promises.push(promise)
    }
    let values = await Promise.all(promises)
    Log.log('wizard-opening: all chips', values)

    chipsets = _.map(chipsets, (chipset, csIndex) => {
      let chipsetUuid = chipset.uuid
      let chips = values[csIndex] && values[csIndex].data
      // map chips into proper chips format
      // input: uuid, colour, value, count
      // output: color, colorBg, colorText, title, value, count
      chips = ChipCalculate.mapChips(chips, [], chipsetUuid)
      return {
        ...chipset,
        chips,
      }
    })
    let chipset = _.find(chipsets, (cset) => cset.name && cset.name.toLowerCase() === 'cash chip')
    chipset = (chipset && chipset.chips) || []
    setChips(chipset)
    return chipset
  }

  const getFloat = useCallback(async (tableUuid) => {
    let response = await ApiTable.getFloat(source.token, tableUuid)
    if (!response || !response.data) throw new Error('invalid float data')
    let data = response.data
    Log.log('chipsmanagement: getFloat', data)
    setFloatInfo(data)
    return data
  }, [])

  const tabulateChipsets = useCallback((chipset, finfo) => {
    let amount = finfo?.total_float
    amount = parseInt(amount, 10)
    let chipinfos = ChipCalculate.detabulateChipsByAmount(chipset, amount)
    setChips(chipinfos)
    // console.log('-- chipset', chipset)
    // console.log('-- chipinfos', chipinfos)
    // console.log('-- amount', amount)
  })

  // const onChipChangeValue = useCallback((chip, count) => {
  //   const allChips = tabs[tabIndex] && tabs[tabIndex].chips

  //   // validate
  //   if (count == null || isNaN(parseInt(count, 10))) {
  //     count = 0
  //   } else {
  //     count = parseInt(count, 10)
  //   }

  //   let val = chip.value
  //   let cid = chip.cid
  //   let found = _.find(allChips, (c) => c.value === val && c.cid === cid)
  //   if (found) {
  //     found.count = count
  //   }
  //   setTabs([...tabs])
  // }, [tabs, tabIndex])

  // const onReset = () => {
  //   //
  // }

  // const onEnter = () => {
  //   //
  // }

  const onDialogUpdateSetVisible = useCallback((isVisible) => {
    setDialogUpdateVisible(isVisible)
    // if (isVisible) {
    //   setDialogUpdateVisible(true)
    // } else {
    //   setDialogStatus(undefined)
    //   setDialogUpdateVisible(false)
    // }
  })

  // const onDialogMessagePressed = useCallback(() => {
  //   switch (dialogStatus) {
  //     case 'message':
  //       setDialogMessage('Update Successful!')
  //       setDialogStatus('success')
  //       break
  //     case 'success':
  //       setDialogMessage('')
  //       setDialogStatus(undefined)
  //       setDialogUpdateVisible(false)
  //       break
  //     default:
  //       // initial visible
  //       setDialogMessage('Please Swipe/Scan Supervisor or Manager ID')
  //       setDialogStatus('message')
  //       setDialogUpdateVisible(true)
  //       break
  //   }
  // }, [dialogStatus])

  return (
    <View style={styles.container}>
      <View style={styles.containerTop}>
        <ChipsInfoPanel
          loading={loading}
          info={floatInfo}
          difference={difference}
          total={total}
          onUpdatePressed={() => onDialogUpdateSetVisible(true)}
        />
      </View>
      {/* <View style={styles.containerTabs}>
        <Tabs
          tabs={tabs}
          index={tabIndex}
          onChangeTab={setTabIndex}
          styleActive={{padding: 3 * SC.SCALE, backgroundColor:Colors.DASH_TAB_2}}
          styleInactive={{padding: 3 * SC.SCALE, backgroundColor:'transparent'}}
        />
      </View> */}
      <View style={styles.containerBottom}>
        {
          <ChipsLayout
            chips={chips}
            setChips={setChips}
          />
          // tabs.map((tab, tindex) => {
          //   if (tindex !== tabIndex) return null
          //   return (
          //     <ChipsLayout
          //       key={`${tindex}`}
          //       chips={chips}
          //       setChips={setChips}
          //     />
          //   )
          // })
        }
        {/* <View style={styles.left}>
          <View style={styles.leftTop}>
            <Tabs
              tabs={tabs}
              index={tabIndex}
              onChangeTab={setTabIndex}
              type={'compact'}
              styleActive={{backgroundColor:Colors.DASH_TAB_2}}
              styleInactive={{backgroundColor:'transparent'}}
            />
          </View>
          <View style={styles.leftMain}>
            {
              tabs && tabIndex < tabs.length &&
              tabs[tabIndex] && tabs[tabIndex].chips ?
              <Chips
                chips={tabs[tabIndex].chips}
                onPress={(chip, index) => setChipIndex(index)}
                onChangeValue={(chip, value) => onChipChangeValue(chip, value)}
              /> : null
            }
          </View>
        </View>
        <View style={styles.right}>
          <View style={styles.rightStub}></View>
          <View style={styles.rightTop}>
            <View style={styles.panel}>
              {
                tabs && tabs.length > 0 &&
                tabIndex < tabs.length &&
                tabs[tabIndex] &&
                tabs[tabIndex].chips &&
                chipIndex < tabs[tabIndex].chips.length ?
                <ChipInputSlider
                  chip={tabs[tabIndex].chips[chipIndex]}
                  min={0}
                  max={100}
                  onChangeValue={onChipChangeValue}
                /> : null
              }
            </View>
          </View>
          <View style={styles.rightCenter}>
            <View style={styles.infoWrapper}>
              <View style={styles.infoLine}>
                <Text style={styles.infoLabel}>Drop Amount:</Text>
                <Text style={styles.infoCurrency}>RM</Text>
                <Text style={styles.infoValue}>2600</Text>
              </View>
              <View style={styles.infoLine}>
                <Text style={[styles.infoLabel, styles.infoRed]}>Chips Amount:</Text>
                <Text style={[styles.infoCurrency, styles.infoRed]}>RM</Text>
                <Text style={[styles.infoValue, styles.infoRed]}>2600</Text>
              </View>
            </View>
          </View>
          <View style={[styles.rightBottom]}>
            <Button
              title="Reset"
              disabled={false}
              onPress={onReset}
              style={[styles.button, styles.buttonReset]} />
            <Button
              title="Enter"
              disabled={false}
              onPress={onEnter}
              style={styles.button} />
          </View>
        </View> */}
      </View>
      <DialogUpdate
        visible={dialogUpdateVisible}
        setVisible={onDialogUpdateSetVisible}
        total={total}
      />
    </View>
  )
})

const styles = StyleSheet.create({
  container: {
    borderRadius: SC.BORDER_RADIUS,
    // backgroundColor:'red',
    flex:1,
    flexGrow:1,
    flexDirection:'column',
    justifyContent: 'center',
    alignItems:'center',
    backgroundColor: Colors.DASH_MAIN,
    margin: SC.GAP_S * SC.SCALE,
    marginRight: 0,
    padding: SC.GAP_S * SC.SCALE,
  },
  containerTop: {
    // backgroundColor: 'pink',
    flex: 1,
    width: '100%',
    margin: SC.GAP_S * SC.SCALE,
    padding: SC.GAP_S * SC.SCALE,
    paddingBottom: 0,
  },
  containerTabs: {
    width: '100%',
    flexDirection: 'row',
    padding: SC.GAP_S * SC.SCALE,
  },
  containerBottom: {
    // ...StyleSheet.absoluteFill,
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    flex: 3,
    width: '100%',
  },
  // left
  left: {
    width: '65%',
    height: '100%',
    // backgroundColor: 'green',
    position: 'relative',
    padding: SC.GAP_S * SC.SCALE,
    flexDirection: 'column',
  },
  leftTop: {
    // width: '100%',
    // flexWrap: 'wrap',
    flexDirection: 'row',
  },
  leftMain: {
    width: '100%',
    // height: '100%',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderBottomLeftRadius: 9,
    backgroundColor: Colors.DASH_MAIN,
  },
  // right
  right: {
    width: '35%',
    height: '100%',
    position: 'relative',
    flexDirection: 'column',
    paddingRight: SC.GAP_S * SC.SCALE,
    paddingBottom: SC.GAP_S * SC.SCALE,
  },
  rightStub: {
    height: 22 * SC.SCALE,
  },
  rightTop: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.DASH_MAIN,
    borderTopRightRadius: 9,
  },
  rightCenter: {
    flexWrap: 'wrap',
    padding: SC.GAP * SC.SCALE,
    paddingBottom: 0,
    backgroundColor: Colors.DASH_MAIN,
  },
  rightBottom: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: SC.GAP * SC.SCALE,
    backgroundColor: Colors.DASH_MAIN,
    marginBottom: SC.GAP_S * SC.SCALE,
    borderBottomRightRadius: 9,
  },
  button: {
    // width: '100%',
    flex: 1,
    backgroundColor: Colors.BUTTON_WIZ,
  },
  buttonReset: {
    backgroundColor: Colors.BUTTON_RESET,
    marginRight: SC.GAP_S * SC.SCALE,
  },
  // // modal
  // modalText: {
  //   marginBottom: 15,
  //   textAlign: 'center',
  //   fontWeight: 'bold',
  // },
})

ChipsManagement.displayName = 'ChipsManagement'

// ChipsManagement.propTypes = {
//   navigation: PropTypes.any,
//   setLoading: PropTypes.func,
// }

export default ChipsManagement
